import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CourseCardSkeleton = (data) => {
  return (
    <SkeletonTheme color="#F5F5F5" highlightColor="#ffffff">
      <div className="drop-shadow-md relative">
        <div className="box-border  h-11 w-[177px] bg-[#2c2c2e] rounded-t-xl"></div>
        <Skeleton className="h-40 m-0 p-0 rounded-b-2xl" />
      </div>
    </SkeletonTheme>
  );
};

export default CourseCardSkeleton;
