import React from "react";
import { Route, Routes } from "react-router-dom";
import "./assets/bariol_bold-webfont.ttf";
import Courses from "./pages/Courses";
import CoursesDetail from "./pages/CourseDetail";
import ErrorPage from "./pages/ErrorPage";

const App = () => {
  localStorage.clear();
  return (
    <Routes>
      <Route index element={<Courses />} exact />
      <Route path="/course/:id" element={<CoursesDetail />} />
      <Route path="/404" element={<ErrorPage />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

export default App;
