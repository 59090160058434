import React from "react";

const ErrorPage = () => {
  return (
    <div className="h-screen w-screen flex flex-col place-items-center pl-4 pr-4 pt-10 bg-white">
      <p className="font-Bariol text-7xl text-center text-[#d1d1d1] pb-10">
        404
      </p>
      <p className="font-Bariol text-5xl text-center text-[#d1d1d1]">
        Opps. <br /> There was a problem loading the website. Meanwhile take a
        nap or get a drink! Hopefully it will be right back up unless the
        developer is too lazy!
      </p>
    </div>
  );
};

export default ErrorPage;
