import React, { useState } from "react";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";

const CommentCard = ({ comment, colors }) => {
  const [open, setOpen] = useState(false);

  if (!open) {
    return (
      <div
        onClick={() => {
          setOpen(true);
        }}
        className="flex flex-row justify-between items-center mt-2 mb-2 bg-[#E3E3E3] rounded-md pl-3 pr-3 pt-1 pb-1 cursor-pointer"
      >
        <p className="font-Bariol text-[#6E7376] ">Comment</p>
        <AiFillCaretRight style={{ color: colors }} />
      </div>
    );
  } else {
    return (
      <>
        <div
          onClick={() => {
            setOpen(false);
          }}
          className="flex cursor-pointer flex-row justify-between items-center  bg-[#E3E3E3] rounded-md mt-2 mb-2 pl-3 pr-3 pt-1 pb-1"
        >
          <p className="font-Bariol text-[#6E7376] ">Comment</p>
          <AiFillCaretDown style={{ color: colors }} />
        </div>
        <div className=" p-3 bg-[#E3E3E3] rounded-md font-Bariol text-[#3e3f41]">
          {comment}
        </div>
      </>
    );
  }
};

export default CommentCard;
