import React from "react";
import { FaChalkboardTeacher } from "react-icons/fa";
import { Link } from "react-router-dom";

const CourseCard = (data) => {
  const num = Math.floor(Math.random() * 6);

  const color = [
    ["#00ab15", "#c6ebcc"],
    ["#f80d6a", "#f8cbdb"],
    ["#feaf1b", "#f9eacb"],
    ["#d33342", "#f1d3d3"],
    ["#0389da", "#cbe6f1"],
    ["#745c4e", "#bea99e"],
  ];

  const courseID = data.data[1].course_name.slice(0, 8);
  const courseMajor = data.data[1].course_name.slice(0, 4);
  const courseCredits = data.data[1].course_name.split(" ").pop();
  const courseName = data.data[1].course_name.slice(8, -9);
  const profList = data.data[1].course_profs.length;
  const reviewAmt = data.data[1].review_count;

  const isBig = courseID.length + courseCredits.length > 17;

  return (
    <Link to={`/course/${data.data[1].course_id}`} state={num}>
      <div className="drop-shadow-md relative">
        <div className="box-border  h-11 w-[177px] bg-[#2c2c2e] rounded-t-xl"></div>
        {/* <div
        className="grid justify-center items-center absolute z-10 top-3 left-[124px] h-[42px] w-[42px] rounded-full border-[2px] border-white drop-shadow-none"
        style={{ backgroundColor: color[num][0] }}
      >
        <BiMessageAdd style={{ color: "white", fontSize: "20px" }} />
      </div> */}
        <div className="box-border h-40 w-[177px] bg-[#fafafa] rounded-b-2xl relative">
          <div className="grid grid-col-2 grid-flow-col justify-start p-2.5">
            <div
              className="z-10 w-[4.5px] rounded-lg mr-2"
              style={{ backgroundColor: color[num][0] }}
            ></div>
            <div className=" font-Bariol grid grid-row-3 grid-flow-row items-center ">
              {isBig ? (
                <p className="text-[16px] text-[#373737]">
                  {courseID} {courseCredits}
                </p>
              ) : (
                <p className="text-[18px] text-[#373737]">
                  {courseID} {courseCredits}
                </p>
              )}
              <p className="text-[13px] text-[#373737] leading-[14px]">
                {courseName}
              </p>
              <p className="text-[13px] text-[#9f9ea5] pt-3">
                {reviewAmt} {reviewAmt !== 1 ? "REVIEWS" : "REVIEW"}
              </p>
            </div>
          </div>
          <div
            className="flex justify-center items-center w-11 h-5 rounded-2xl absolute bottom-4 ml-4 pb-[1px]"
            style={{ backgroundColor: color[num][1] }}
          >
            <FaChalkboardTeacher
              className="w-[12px] mr-1"
              style={{ color: color[num][0] }}
            />
            <p
              className=" text-sm font-Bariol"
              style={{ color: color[num][0] }}
            >
              {profList}
            </p>
          </div>
          <div className="absolute text-[38px] text-[#f2f2f2] font-Bariol right-0 pr-2 bottom-0">
            {courseMajor}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CourseCard;
