import React, { useEffect, useRef, useState } from "react";
import MahidolLogo from ".././assets/mahidolLogo.png";
import { TbMinusVertical } from "react-icons/tb";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import CommentCard from "../components/CommentCard";
import ReactTimeAgo from "react-time-ago";
import { IoCheckmarkOutline } from "react-icons/io5";
import Skeleton from "react-loading-skeleton";
import { BeatLoader } from "react-spinners";
import ReactStars from "react-rating-stars-component";
import RatingCard from "../components/RatingCard";
import { IoMdAddCircle, IoMdCloseCircle } from "react-icons/io";

const CourseDetail = () => {
  const navigate = useNavigate();
  const id = useParams().id;
  const [courses, setCourses] = useState({});
  const [reviews, setReviews] = useState([]);
  const comment = useRef();
  const [commentst, setCommentst] = useState("");
  const [hidden, setHidden] = useState(false);
  const [selectedProf, setSelectedProf] = useState("");
  const [selectedProfID, setSelectedProfID] = useState(null);
  const [addReviewMode, setAddReviewMode] = useState(false);
  const [loading, SetLoading] = useState(true);
  const [profRating, setProfRating] = useState("");
  const [workloadRating, setWorkloadRating] = useState("");
  const [assignmentsRating, setAssignmentsRating] = useState("");
  const [projectsRating, setProjectsRating] = useState("");
  const [midtermRating, setMidtermRating] = useState("");
  const [quizesRating, setQuizesRating] = useState("");
  const [finalRating, setFinalRating] = useState("");
  const [createdReview, setCreatedReview] = useState({});

  useEffect(() => {
    setCreatedReview({
      prof: selectedProf,
      prof_score: profRating,
      workload: workloadRating,
      assignments: assignmentsRating,
      projects: projectsRating,
      quizes: quizesRating,
      midterm: midtermRating,
      final: finalRating,
      comment: commentst,
      course_id: courses._id,
    });
  }, [
    selectedProf,
    profRating,
    workloadRating,
    courses._id,
    assignmentsRating,
    projectsRating,
    quizesRating,
    midtermRating,
    finalRating,
    commentst,
  ]);

  useEffect(() => {
    console.log(createdReview);
  }, [createdReview]);

  const handlePostReview = () => {
    axios({
      method: "post",
      url: "https://sky-api.vercel.app/review",
      headers: {
        APIKEY: process.env.REACT_APP_APIKEY,
      },
      data: createdReview,
    })
      .then((result) => {
        localStorage.removeItem("data");
        localStorage.removeItem(`data-${id}`);
        console.log(result);
        navigate(0);
      })
      .catch((err) => {
        console.log(err);
        navigate("/404");
      });
  };

  useEffect(() => {
    if (localStorage.getItem(`data-${id}`) === null) {
      axios
        .get(`https://sky-api.vercel.app/course/${id}/review`, {
          headers: {
            APIKEY: process.env.REACT_APP_APIKEY,
          },
        })
        .then((response) => {
          console.log("ran from api");
          localStorage.setItem(`data-${id}`, JSON.stringify(response.data));
          setCourses(response.data[0]);
          setReviews(response.data[1]);
          SetLoading(false);
        })
        .catch((err) => {
          navigate("/404");
        });
    } else {
      console.log("ran from localstorage");
      setCourses(JSON.parse(localStorage.getItem(`data-${id}`))[0]);
      setReviews(JSON.parse(localStorage.getItem(`data-${id}`))[1]);
      SetLoading(false);
    }
  }, [navigate, id]);

  const { state } = useLocation();
  const num = state == null ? 0 : state;

  const color = [
    ["#00ab15", "#c6ebcc"],
    ["#f80d6a", "#f8cbdb"],
    ["#feaf1b", "#f9eacb"],
    ["#d33342", "#f1d3d3"],
    ["#0389da", "#cbe6f1"],
    ["#745c4e", "#bea99e"],
  ];

  document.body.style = `background: ${color[num][1]}; `;

  if (loading) {
    return (
      <div className="h-screen lg:w-8/12 lg:mx-auto flex flex-col bg-white">
        <div
          className="h-[25%] w-screen lg:w-full lg:mx-auto flex pt-5 pb-5 flex-col justify-around relative shadow-xl"
          style={{ backgroundColor: color[num][0] }}
        >
          <Link to=".." className="pb-3 flex justify-start items-center w-max">
            <div className="w-1 bg-white opacity-90 h-12 rounded-r-md"></div>
            <img alt="" src={MahidolLogo} className="h-12 ml-3" />
            <p className="font-Bariol text-2xl ml-3 text-white">
              MUIC Course Review
            </p>
          </Link>
          <div className="pb-5 grid grid-flow-col grid-cols-2">
            <div className="pl-5">
              <p className="font-Bariol text-white text-xl">
                <Skeleton
                  className="w-40 h-8"
                  baseColor={color[num][0]}
                  borderRadius={5}
                />
              </p>
              <p className="font-medium  text-white text-xs">
                <Skeleton
                  className="w-40 h-3"
                  baseColor={color[num][0]}
                  borderRadius={5}
                />
              </p>
            </div>
          </div>
          <div className="ml-5">
            <p className="flex text-white font-medium text-xs">
              <Skeleton
                className="w-40 h-3"
                baseColor={color[num][0]}
                borderRadius={5}
              />
            </p>
          </div>
        </div>
        <div className="flex flex-grow justify-center items-center ">
          <BeatLoader
            color={color[num][0]}
            loading={loading}
            size={20}
            aria-label="Loading Spinner"
          />{" "}
        </div>
      </div>
    );
  } else
    return (
      <div className="lg:w-8/12 lg:mx-auto bg-white">
        <div className=" min-h-screen flex flex-col">
          <div
            className="w-screen lg:w-full lg:mx-auto flex pt-5 pb-5 flex-col justify-around relative"
            style={{ backgroundColor: color[num][0] }}
          >
            <Link
              to=".."
              className="pb-3 flex justify-start items-center w-max"
            >
              <div className="w-1 bg-white opacity-90 h-12 rounded-r-md"></div>
              <img alt="" src={MahidolLogo} className="h-12 ml-3" />
              <p className="font-Bariol text-2xl ml-3 text-white">
                MUIC Course Review
              </p>
            </Link>
            <div className="pb-5 grid grid-flow-col grid-cols-2">
              <div className="pl-5">
                <p className="font-Bariol text-white text-xl">
                  {courses.course_name.slice(0, 8)}
                  {courses.course_name.split(" ").pop()}
                </p>
                <p className="font-medium  text-white text-xs">
                  {courses.course_name.slice(8, -9)}
                </p>
              </div>

              <div className=" flex justify-end pr-2 pt-2 text-7xl text-[#f2f2f2] font-Bariol opacity-40">
                {courses.course_name.slice(0, 4)}
              </div>
            </div>
            <div className="ml-5">
              <div className="flex text-white font-medium text-xs">
                {reviews.length}
                {reviews.length !== 1 ? " REVIEWS" : " REVIEW"}
                <TbMinusVertical className="text-lg mt-[-1px]" />
                {courses.course_profs.length}
                {courses.course_profs.length !== 1
                  ? " INSTRUCTORS"
                  : " INSTRUCTOR"}
              </div>
            </div>
          </div>
          <button
            onClick={() =>
              addReviewMode ? setAddReviewMode(false) : setAddReviewMode(true)
            }
            className=" m-3 mb-0 p-3  rounded-md text-[#f2f2f2] text-center text-lg"
            style={{ backgroundColor: color[num][0] }}
          >
            {!addReviewMode ? "Add Review" : "View Reviews"}
          </button>
          <div className="flex justify-end ml-5 mr-5 mt-1">
            <p className=" font-medium text-xs mt-2 text-[#B9B9BE] text-right">
              * All reviews are completely anonymous and are solely based on
              personal opinions.
            </p>
          </div>
          {!addReviewMode ? (
            <div>
              {Object.entries(reviews)
                .sort((a, b) => {
                  return (
                    new Date(b[1].review_date) - new Date(a[1].review_date)
                  );
                })
                .map((review) => (
                  <div key={review[0]}>
                    <div className="m-3 p-3 bg-[#F6F5F5] rounded-md">
                      <div className="flex flex-row justify-between">
                        <p className="font-Bariol text-[#6E7376] ">
                          Review: {parseInt(review[0]) + 1}
                        </p>
                        <p className="font-Bariol text-[#6E7376] ">
                          {
                            <ReactTimeAgo
                              date={new Date(review[1].review_date)}
                              locale="en-US"
                            />
                          }
                        </p>
                      </div>
                      <div className="grid grid-flow-col whitespace-nowrap overflow-scroll mt-0.5  p-2 ">
                        {review[1].prof_score !== "" && (
                          <div className="flex flex-col justify-center items-center place-content-center w-[100px]">
                            <div
                              className="font-bold text-2xl"
                              style={{ color: color[num][0] }}
                            >
                              {review[1].prof_score}
                              <span className="text-xs">/5.0</span>
                            </div>
                            <div className="font-Bariol text-[#6E7376] whitespace-nowrap">
                              {review[1].prof.length > 14
                                ? `${
                                    review[1].prof
                                      .toLowerCase()
                                      .split(" ")
                                      .map(
                                        (w) =>
                                          w[0].toUpperCase() +
                                          w.substring(1).toLowerCase()
                                      )
                                      .join(" ")
                                      .split(" ")[0]
                                  } ${review[1].prof
                                    .split(" ")[1]
                                    .slice(0, 2)
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (w) =>
                                        w[0].toUpperCase() +
                                        w.substring(1).toLowerCase()
                                    )
                                    .join(" ")}.`
                                : review[1].prof
                                    .toLowerCase()
                                    .split(" ")
                                    .map(
                                      (w) =>
                                        w[0].toUpperCase() +
                                        w.substring(1).toLowerCase()
                                    )
                                    .join(" ")}
                            </div>
                            <div className="font-Bariol m-0 p-0 text-xs text-[#6E7376] whitespace-nowrap">
                              Personal Rating
                            </div>
                          </div>
                        )}
                        {review[1].workload !== "" && (
                          <div className="flex flex-col justify-center items-center place-content-center w-[125px]">
                            <div
                              className="font-bold text-2xl "
                              style={{ color: color[num][0] }}
                            >
                              {review[1].workload}
                              <span className="text-xs">/5.0</span>
                            </div>
                            <div className="font-Bariol text-[#6E7376]">
                              Workload
                            </div>
                            <div className="font-Bariol m-0 p-0 text-xs text-[#6E7376] whitespace-nowrap">
                              Quantity
                            </div>
                          </div>
                        )}
                        {review[1].projects !== "" && (
                          <div className="flex flex-col justify-center items-center place-content-center w-[125px]">
                            <div
                              className="font-bold text-2xl "
                              style={{ color: color[num][0] }}
                            >
                              {review[1].projects}
                              <span className="text-xs">/5.0</span>
                            </div>
                            <div className="font-Bariol text-[#6E7376]">
                              Projects
                            </div>
                            <div className="font-Bariol m-0 p-0 text-xs text-[#6E7376] whitespace-nowrap">
                              Quantity
                            </div>
                          </div>
                        )}
                        {review[1].assignments !== "" && (
                          <div className="flex flex-col justify-center items-center place-content-center w-[125px]">
                            <div
                              className="font-bold text-2xl "
                              style={{ color: color[num][0] }}
                            >
                              {review[1].assignments}
                            </div>
                            <div className="font-Bariol text-[#6E7376]">
                              Assignments
                            </div>
                            <div className="font-Bariol m-0 p-0 text-xs text-[#6E7376] whitespace-nowrap">
                              Difficulty
                            </div>
                          </div>
                        )}
                        {review[1].quizes !== "" && (
                          <div className="flex flex-col justify-center items-center place-content-center w-[125px]">
                            <div
                              className="font-bold text-2xl "
                              style={{ color: color[num][0] }}
                            >
                              {review[1].quizes}
                              <span className="text-xs">/5.0</span>
                            </div>
                            <div className="font-Bariol text-[#6E7376]">
                              Quizes
                            </div>
                            <div className="font-Bariol m-0 p-0 text-xs text-[#6E7376] whitespace-nowrap">
                              Difficulty
                            </div>
                          </div>
                        )}
                        {review[1].midterm !== "" && (
                          <div className="flex flex-col justify-center items-center place-content-center w-[125px]">
                            <div
                              className="font-bold text-2xl "
                              style={{ color: color[num][0] }}
                            >
                              {review[1].midterm}
                              <span className="text-xs">/5.0</span>
                            </div>
                            <div className="font-Bariol text-[#6E7376]">
                              Midterm
                            </div>
                            <div className="font-Bariol m-0 p-0 text-xs text-[#6E7376] whitespace-nowrap">
                              Difficulty
                            </div>
                          </div>
                        )}
                        {review[1].final !== "" && (
                          <div className="flex flex-col justify-center items-center place-content-center w-[125px]">
                            <div
                              className="font-bold text-2xl "
                              style={{ color: color[num][0] }}
                            >
                              {review[1].final}
                              <span className="text-xs">/5.0</span>
                            </div>
                            <div className="font-Bariol text-[#6E7376]">
                              Final
                            </div>
                            <div className="font-Bariol m-0 p-0 text-xs text-[#6E7376] whitespace-nowrap">
                              Difficulty
                            </div>
                          </div>
                        )}
                      </div>
                      {review[1].comment !== "" ? (
                        <CommentCard
                          comment={review[1].comment}
                          colors={color[num][0]}
                        />
                      ) : (
                        <div className="flex flex-row justify-between items-center mt-2 mb-2 bg-[#E3E3E3] rounded-md pl-3 pr-3 pt-1 pb-1">
                          <p className="font-Bariol text-[#6E7376] ">
                            No Comment
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              {reviews.length > 0 ? (
                <></>
              ) : (
                <div className="flex flex-grow justify-center  place-items-center">
                  <p className=" text-[#B9B9BE] text-center text-lg mt-10">
                    No Reviews
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="m-3 p-3 bg-[#F6F5F5] rounded-md relative">
                <div className="flex flex-row  place-items-center">
                  <p className="font-Bariol text-xl text-[#6E7376] flex place-items-center justify-center w-5/12">
                    Instructor
                  </p>

                  {!hidden ? (
                    <IoMdCloseCircle
                      className="font-Bariol text-[24px] text-[#808080] cursor-pointer absolute right-2 top-2"
                      onClick={() => {
                        setProfRating("");
                        setSelectedProf("");
                        setSelectedProfID(null);
                        setHidden(true);
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <div className="flex flex-grow justify-center">
                    {!hidden ? (
                      <> </>
                    ) : (
                      <div
                        className="flex flex-row place-items-center cursor-pointer"
                        onClick={() => {
                          setProfRating("");
                          setHidden(false);
                        }}
                      >
                        <IoMdAddCircle className="font-Bariol text-[28px] text-[#808080] cursor-pointer" />
                        <p className="font-Bariol text-md text-[#6E7376] ml-1">
                          Add Rating
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {!hidden && (
                  <>
                    {courses.course_profs.map((prof, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            setSelectedProf(prof);
                            setSelectedProfID(i);
                          }}
                          className=" cursor-pointer bg-[#E3E3E3] rounded-md flex flex-row justify-between items-center place-content-center mt-2 mb-2 pl-3 pr-3 pt-1 pb-1"
                        >
                          <p className="font-Bariol text-[#6E7376]">
                            {prof
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (w) =>
                                  w[0].toUpperCase() +
                                  w.substring(1).toLowerCase()
                              )
                              .join(" ")}
                          </p>
                          {selectedProfID === i ? (
                            <IoCheckmarkOutline
                              className=" text-xl"
                              style={{ color: color[num][0] }}
                            />
                          ) : (
                            <IoCheckmarkOutline className=" text-xl" />
                          )}
                        </div>
                      );
                    })}
                    {selectedProfID === null && (
                      <p
                        className="font-Bariol text-md text-center"
                        style={{ color: color[num][0] }}
                      >
                        Select one to rate.
                      </p>
                    )}

                    <div className="flex flex-row justify-center place-items-center">
                      {selectedProfID !== null && (
                        <>
                          <ReactStars
                            size={30}
                            isHalf={true}
                            activeColor={color[num][0]}
                            a11y={false}
                            onChange={(newRating) => {
                              setProfRating(newRating.toFixed(1));
                            }}
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
              <RatingCard
                name="Workload"
                color={color[num][0]}
                unit="Quantity"
                singlerating={(rating) => {
                  setWorkloadRating(rating);
                }}
              />
              <RatingCard
                name="Projects"
                color={color[num][0]}
                unit="Quantity"
                singlerating={(rating) => {
                  setProjectsRating(rating);
                }}
              />
              <RatingCard
                name="Assignments"
                color={color[num][0]}
                unit="Difficulty"
                singlerating={(rating) => {
                  setAssignmentsRating(rating);
                }}
              />
              <RatingCard
                name="Quizes"
                color={color[num][0]}
                unit="Difficulty"
                singlerating={(rating) => {
                  setQuizesRating(rating);
                }}
              />
              <RatingCard
                name="Midterm"
                color={color[num][0]}
                unit="Difficulty"
                singlerating={(rating) => {
                  setMidtermRating(rating);
                }}
              />
              <RatingCard
                name="Final"
                color={color[num][0]}
                unit="Difficulty"
                singlerating={(rating) => {
                  setFinalRating(rating);
                }}
              />
              <div className="m-3 p-3 bg-[#F6F5F5] rounded-md flex flex-col">
                <p className="font-Bariol text-xl text-[#6E7376] flex justify-center ml-1 w-5/12 mb-2">
                  Comment
                </p>
                <textarea
                  onChange={() => {
                    setCommentst(comment.current.value);
                  }}
                  onBlur={() => {
                    setCommentst(comment.current.value);
                  }}
                  ref={comment}
                  type="text "
                  className="h-40 p-3 bg-[#e7e7e7] rounded-md font-Bariol text-[#56595c]"
                />
              </div>
              <div className="flex flex-col p-3 pt-0">
                <button
                  onClick={handlePostReview}
                  className="flex-grow text-[#f2f2f2] text-center rounded-md p-3"
                  style={{ backgroundColor: color[num][0] }}
                >
                  Post Review
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default CourseDetail;
