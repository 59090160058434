import React, { useEffect, useState } from "react";
import CourseCard from "../components/CourseCard";
import SearchIcon from ".././assets/searchIcon.png";
import MahidolLogo from ".././assets/mahidolLogo.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CourseCardSkeleton from "../components/CourseCardSkeleton";

const Courses = () => {
  const navigate = useNavigate();
  const [searchSub, setSearchSub] = useState("");
  const [Courses, SetCourses] = useState([]);
  const [loading, SetLoading] = useState(true);

  const handleSearchChange = (e) => {
    setSearchSub(e.target.value);
  };

  useEffect(() => {
    if (localStorage.getItem("data") === null) {
      axios
        .get("https://sky-api.vercel.app/courses/", {
          headers: {
            APIKEY: process.env.REACT_APP_APIKEY,
          },
        })
        .then((response) => {
          console.log("ran from api");
          localStorage.setItem("data", JSON.stringify(response.data));
          SetCourses(response.data);
          SetLoading(false);
        })
        .catch((err) => {
          navigate("/404");
        });
    } else {
      console.log("ran from localstorage");
      SetCourses(JSON.parse(localStorage.getItem("data")));
      SetLoading(false);
      // clear localstorage when create review else it wont refrsh amount
    }

    //add bad request
  }, [navigate]);

  document.body.style = "background: #502B80; ";

  return (
    <div className="lg:w-8/12 mx-auto h-screen flex flex-col bg-white">
      <div className="grid grid-flow-row row-span-3">
        <div className="p-3 flex">
          <div className="font-Bariol text-3xl flex items-end w-11/12">
            <p>MUIC Course Review</p>
          </div>
          <img src={MahidolLogo} alt="" className="h-11 ml-2" />
        </div>
        <div className="p-3 pt-0 flex justify-center w-full">
          <div className="bg-[#eeeeef] w-full h-10 rounded-xl flex items-center justify-start p-2">
            <img src={SearchIcon} alt="" className="w-5 h-5 mr-2" />
            <input
              type="text"
              className="bg-transparent flex-grow focus-visible:outline-none focus:outline-0 font-normal"
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="p-2 pt-0 grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-3  justify-items-center">
          {loading &&
            [...Array(10)].map((elementInArray, index) => (
              <CourseCardSkeleton key={index} />
            ))}
          {!loading &&
            Object.entries(Courses)
              .filter((course) => {
                if (searchSub === "") {
                  return course;
                } else if (
                  course[1].course_name
                    .toLowerCase()
                    .includes(searchSub.toLowerCase())
                ) {
                  return course;
                }
                return false;
              })
              .sort((a, b) => {
                return b[1].review_count - a[1].review_count;
              })
              .slice(0, 20)
              .map((course) => (
                <div key={course[1].course_id}>
                  <CourseCard data={course} />
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default Courses;
