import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { IoMdAddCircle, IoMdCloseCircle } from "react-icons/io";

const RatingCard = ({ name, color, unit, singlerating }) => {
  const [rating, setRating] = useState("");
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    singlerating(rating);
  }, [rating, singlerating]);

  return (
    <div className="m-3 p-3 bg-[#F6F5F5] rounded-md flex flex-row relative">
      <div className="flex flex-col place-items-center justify-center w-5/12 ">
        <p className="font-Bariol text-xl text-[#6E7376] ">{name}</p>
        <p className="font-Bariol text-sm text-[#6E7376]">{unit}</p>
      </div>
      {!hidden ? (
        <IoMdCloseCircle
          className="font-Bariol right-2 top-2 text-[24px] text-[#808080] absolute cursor-pointer"
          onClick={() => {
            setRating("");
            setHidden(true);
          }}
        />
      ) : (
        <></>
      )}
      <div className="flex flex-row flex-grow justify-center place-items-center">
        {!hidden ? (
          <>
            <ReactStars
              size={30}
              isHalf={true}
              activeColor={color}
              a11y={false}
              onChange={(newRating) => {
                setRating(newRating.toFixed(1));
              }}
            />
          </>
        ) : (
          <div
            className="flex flex-row place-items-center cursor-pointer"
            onClick={() => {
              setRating("");
              setHidden(false);
            }}
          >
            <IoMdAddCircle className="font-Bariol text-[28px] text-[#808080] mr-0.5 cursor-pointer" />
            <p className="font-Bariol text-md text-[#6E7376] ml-1">
              Add Rating
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default RatingCard;
